import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Page from "~common/components/Page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Page seo={{
  title: "Terms and Conditions"
}}>
    <Page.Header downArrows>
      <Page.Header.Title>Terms and Conditions</Page.Header.Title>
    </Page.Header>
    <Page.Body sx={{
    py: [5, 6]
  }}>{children}</Page.Body>
  </Page>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`General Sale Terms`}</h1>
    <h3>{`1.1.`}</h3>
    <p>{`These General Sale Terms are applicable to products purchased under the brand name “Almagesto” , hereinafter “Product” , through the e-commerce site `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{`, hereinafter the “Site”, by users qualified as “Customers” for the intents and purposes of the following article 1.2.
The Site “`}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz%E2%80%9D"
      }}>{`www.almagesto.xyz”`}</a>{` and relative brand Almagesto is owned by RGBear di D’Ambrogio Massimo with registered office in Biella, Via Billotti 8 (BI) VAT NO. 02663400022 Economic and Administrative Index No. Chamber of Commerce, Industry and Small Business, hereinafter the “Seller”.
Information in relation to orders and deliveries, refunds and returns can be found in the following sections: Shipping, Privacy Policy, Withdrawal & Refunds, FAQ.
Other types of information can be obtained through the help service by contacting Customer Assistance at the email address `}<a parentName="p" {...{
        "href": "mailto:info@almagesto.xyz"
      }}>{`info@almagesto.xyz`}</a></p>
    <h3>{`1.2`}</h3>
    <p>{`The Seller reserves the right to change, in compliance with binding legislation, these General Sale Terms at any time, at its own discretion, without needing to give any prior notice to users of the Site.
Any changes made shall be effective from the date of publication on the Site and shall be applicable only to sales finalised from that date on.`}</p>
    <h3>{`1.3`}</h3>
    <p>{`The Seller, through the website `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{`, offers its products for sale and conducts its electronic commerce business exclusively towards final users, who are “consumers” identified as “Customers” .
The term “Consumer” implies any natural person acting on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` for purposes not connected to any commercial, entrepreneurial or professional activities they may conduct.
If the Customer is not a “consumer”, we invite them to refrain from making commercial transactions on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz."
      }}>{`www.almagesto.xyz.`}</a>{`
In view of our sales policy, the Seller reserves the right not to follow through on orders received from users other than “Consumers”, or in any case on orders that do not comply with its sales policy.`}</p>
    <h3>{`1.4`}</h3>
    <p>{`The General Sale Terms do not govern the supply of services or the sale of products by parties other than the Seller, who are present on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` through links, banners or other hypertext links.
Therefore, before placing orders and purchasing products and services from parties other than the Seller, we recommend you check their sale terms, as the Seller shall not be responsible for the supply of services by third parties other than the Seller or for the finalization of electronic sales transactions between users of `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` and third parties.`}</p>
    <h2>{`2. Finalising the Purchase`}</h2>
    <h3>{`2.1`}</h3>
    <p>{`To finalise a purchase for one or more products on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{`, the Customer must fill in the order form electronically and send it to the Seller, through the website interface.`}</p>
    <h3>{`2.2`}</h3>
    <p>{`The contract is finalised when the Seller receives the order form, via the internet, following a check on the correctness of the relative information.
The Customer expressly accepts that fulfilment of the contract by the Seller shall begin only once the price charged for the purchased product/s has been received in the Seller’s account.
Before proceeding to purchase products by transmitting the order form, the Customer must carefully read the General Sale Terms and informative note on the Right of Withdrawal.`}</p>
    <h3>{`2.3`}</h3>
    <p>{`The order form will be filed in the Seller’s database for the period of time necessary to process the orders and in any case in accordance with the law.
Before transmitting the order form, the Customer will be asked to identify and correct any errors in the information provided.`}</p>
    <h3>{`2.4`}</h3>
    <p>{`The Seller may decide not to process order forms that are incomplete or incorrect, if products are unavailable or for other just causes.
In these cases, the Seller will inform the Customer via email that the contract has not been finalized and that the Seller has not processed the purchase order, specifying the reasons.
If the products presented on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` are no longer available or for sale at the time of the Customer’s last access, or when they sent the order form, the Seller will inform the Customer promptly and in any case within thirty (30) days from the day after the date on which the order was sent to the Seller, regarding the non-availability of the products ordered.
If the order form has been sent and the price has been paid, the Seller will refund the amount already paid back to the Customer.`}</p>
    <h3>{`2.5`}</h3>
    <p>{`By sending the order form via the internet, the Customer unconditionally accepts and undertakes to observe these General Sale Terms, in their relations with the Seller.
If you do not agree with any of the terms reported in the General Sale Terms, we invite you not to send the order form to purchase products on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz."
      }}>{`www.almagesto.xyz.`}</a>{`
By sending the order form, the Customer confirms to be aware of and to accept the General Sale Terms and any further information contained in `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{`, also through links, including the privacy policy, payment terms, delivery terms and the informative note on the Right of Withdrawal.`}</p>
    <h3>{`2.6`}</h3>
    <p>{`Once the contract has been finalised, the Seller will send the Customer a receipt for the order purchase via email, containing the information already contained in the order form (reference to General Sale Terms, in addition to information relative to the essential characteristics of the product and a detailed indication of the price, payment methods and delivery costs).`}</p>
    <h2>{`3. Products and prices`}</h2>
    <h3>{`3.1`}</h3>
    <p>{`Only products distinguished by the Almagesto brand are sold on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{`.`}</p>
    <h3>{`3.2`}</h3>
    <p>{`The Seller does not sell used products, irregular products or products of inferior quality with respect to the corresponding standards offered on the market.
The essential characteristics of products are presented on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` in each product data sheet.
In any case, the images and colours of products offered for sale on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` may not correspond to the real ones due to the effects of the internet browser and monitor used.`}</p>
    <h3>{`3.3`}</h3>
    <p>{`Product prices may be subject to change. The Customer must check the final sale price before sending the relative order form.`}</p>
    <h3>{`3.4`}</h3>
    <p>{`Purchase requests from some countries may require longer times to complete the delivery of the order.`}</p>
    <h3>{`3.5`}</h3>
    <p>{`The Seller, in the event the Right of Withdrawal is exercised, has the faculty not to accept products returned which have been altered in their essential and qualitative characteristics, or which are damaged. For further specifications regarding the Right of Withdrawal, see the “Returns & Refunds” section.`}</p>
    <h2>{`4. Payment`}</h2>
    <h3>{`4.1`}</h3>
    <p>{`In order to pay the cost of products and relative dispatch and delivery costs, the Customer must use one of the methods indicated in the order form. For further details, see the `}<a parentName="p" {...{
        "href": "/payment"
      }}>{`Payment Methods`}</a>{` section on the Site.`}</p>
    <h3>{`5.1`}</h3>
    <p>{`The Customer is invited to pay careful attention to the General Sale Terms, which are considered to be wholly known and accepted by the Customer at the time the order form is transmitted.`}</p>
    <h3>{`5.2`}</h3>
    <p>{`The Site indicates the availability of products and their delivery times, but said information is to be considered purely indicative and is not binding on the Seller.`}</p>
    <h3>{`5.3`}</h3>
    <p>{`The Customer undertakes to check, within the term specified by art. 1495 of the Italian Civil Code, that the delivery includes all and only the products purchased and to promptly inform the Seller of any defects in the products received. If the packaging or wrapping of products ordered by the Customer are delivered in a clearly damaged state, the Customer is invited to refuse the delivery by the carrier or accept the delivery “with reserve”.`}</p>
    <h2>{`6. Right of Withdrawal`}</h2>
    <h3>{`6.1`}</h3>
    <p>{`The Customer has the right to withdraw from the contract finalised with the Vendor, without being subject to any penalties, within fourteen (14) days from the day the products purchased on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` are received. For further details regarding the Right of Withdrawal, see the “Withdrawal and Refunds“ section.`}</p>
    <h2>{`7. Privacy`}</h2>
    <h3>{`7.1`}</h3>
    <p>{`The Customer may obtain information regarding how their Personal Data is handled by accessing the Privacy Policy page of this website.`}</p>
    <h2>{`8. Intellectual Property Rights`}</h2>
    <h3>{`8.1`}</h3>
    <p>{`The brand “RGBear” and the brand “Almagesto” are registered brands. The owners of the registered brands have the faculty to use their proprietary brands exclusively. Any use of said brands that is not compliant with the law, insofar as not authorised, is prohibited and shall have serious legal implications. Therefore, under no circumstances is the use of said brands or other distinguishing marks on the `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` Site allowed, including all contents of the Site itself, without the prior written consent of RGBear di D’Ambrogio Massimo.`}</p>
    <h2>{`9. Dispute Resolution`}</h2>
    <h3>{`9.1`}</h3>
    <p>{`The General Sale Terms are governed by Italian law and in particular by legislative decree no.206 of 6th September 2005 on the consumer code, with specific reference to regulations regarding remote contracts and by legislative decree no.70 of 9th April 2003 in regards to certain aspects concerning electronic commerce. In the case of disputes between the Seller and each end user, resulting from the General Sale Terms, the court of Biella shall have exclusive jurisdiction.
Changes and Updates`}</p>
    <h3>{`10.1`}</h3>
    <p>{`The General Sale Terms are changed from time to time, also in consideration of any changed legislation. The new General Sale Terms shall be effective from the date of publication on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{`.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      